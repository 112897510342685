.login {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .login .div {
    background-color: #ffffff;
    border: 1px none;
    height: 100%;
    position: relative;
    width: 100%;
  }
  
  .login .group {
    /* height: 629px; */
    left: 48%;
    position: absolute;
    top: 100px;
    width: 503px;
  }
  
  .login .text-wrapper {
    color: #333333;
    font-family: "Open Sans", Helvetica;
    font-size: 24px;
    font-weight: 600;
    height: 27px;
    left: 0;
    letter-spacing: 0;
    line-height: 26.5px;
    position: absolute;
    top: 68px;
    white-space: nowrap;
  }
  
  .login .p {
    color: #adadad;
    font-family: "Open Sans", Helvetica;
    font-size: 16px;
    font-weight: 400;
    height: 52px;
    left: 0;
    letter-spacing: 0;
    line-height: 26px;
    position: absolute;
    top: 113px;
    width: 497px;
  }
  
  .login .h-1 {
    color: #333333;
    font-family: "Open Sans", Helvetica;
    font-size: 36px;
    font-weight: 700;
    height: 27px;
    left: 0;
    letter-spacing: 0;
    line-height: 26.5px;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .login .group-2 {
    /* height: 422px; */
    left: 0;
    position: absolute;
    top: 207px;
    width: 499px;
  }
  
  .login .input-filled {
    /* background-color: #ffffff; */
    /* border: 1px solid; */
    border-color: #e0e4e6;
    border-radius: 6px;
    height: 50px;
    left: 0;
    position: absolute;
    top: 92px;
    width: 497px;
  }
  
  .login .overlap {
    height: 50px;
    position: relative;
    width: 500px;
  }
  
  .login .input-BG {
    border-radius: 8px;
    box-shadow: var(--nav-bot);
    height: 50px;
    left: 0;
    position: absolute;
    top: 0;
    width: 497px;
    color: #9e9e9e;
    font-family: "Open Sans", Helvetica;
    font-size: 20px;
    border: 1px solid
  }
  
  .login .placeholder {
    color: #9e9e9e;
    font-family: "Open Sans", Helvetica;
    font-size: 24px;
    font-weight: 400;
    height: 51px;
    left: 25px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 10px;
    width: 475px;
  }
  
  .login .feather-icon-eye-off {
    height: 24px;
    left: 457px;
    position: absolute;
    top: 15px;
    width: 24px;
  }
  
  .login .shape {
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px;
  }
  
  .login .img {
    height: 18px;
    left: 0;
    position: absolute;
    top: 3px;
    width: 24px;
  }
  
  .login .size {
    height: 24px;
    /* left: 5838px; */
    position: absolute;
    /* top: 1076px; */
    width: 24px;
  }
  
  .login .overlap-wrapper {
    /* background-color: #ffffff; */
    /* border: 1px solid; */
    border-color: #e0e4e6;
    border-radius: 6px;
    height: 50px;
    left: 0;
    position: absolute;
    top: 0;
    width: 497px;
  }
  
  .login .primary {
    background-color: #b89d4d;
    border-radius: 6px;
    box-shadow: var(--dropshadow-1);
    height: 50px;
    left: 0;
    position: absolute;
    top: 220px;
    width: 497px;
  }
  
  .login .continue {
    color: #ffffff;
    font-family: "Open Sans", Helvetica;
    font-size: 20px;
    font-weight: 600;
    height: 20px;
    left: 12px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    text-align: center;
    top: 15px;
    width: 472px;
  }
  
  .login .text-wrapper-2 {
    color: #333333;
    font-family: "Open Sans", Helvetica;
    font-size: 20px;
    font-weight: 400;
    height: 40px;
    left: 36px;
    letter-spacing: 0;
    line-height: 18px;
    position: absolute;
    top: 310px;
    width: 180px;
  }
  
  .login .element-forms-elements {
    height: 24px;
    left: 0;
    position: absolute;
    top: 310px;
    width: 24px;
  }
  
  .login .ic-check-box {
    height: 18px;
    left: 3px;
    position: absolute;
    top: 3px;
    width: 18px;
  }
  
  .login .left {
    /* height: 1080px; */
    left: 0;
    position: absolute;
    top: 0;
    width: 50%;
  }
  
  .login .frame {
    height: 85px;
    left: 55%;
    position: absolute;
    top: 25px;
    width: 216px;
  }

  .label {
    border: 0px none;
    height: 51px;
    width: 475px;
}

.label .placeholder {
    color: #9e9e9e;
    font-family: "Open Sans-Regular", Helvetica;
    font-size: 24px;
    font-weight: 400;
    height: 51px;
    left: 0;
    letter-spacing: 0;
    line-height: 20px;
    position: fixed;
    top: 0;
    width: 475px;
}

.login .continue a{
  text-decoration: none;
  color:#ffffff;
}

.active-tab {
  background-color: #007bff; /* Blue color for active tab */
  color: white; /* White text color for active tab */
  border: 1px solid #007bff; /* Blue border for active tab */
  border-radius: 4px; /* Rounded corners for active tab */
  padding: 5px 10px; /* Padding for active tab */
}

.validation-message {
  color: red;
  margin-top: 5px;
}

.dEWMLH {
  display: none;
}
.sc-gKHVLF{ 
  display: none;
}


.pass_show{
  margin-left: 87%;
  margin-top: 55px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px;
}

